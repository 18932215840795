import React from 'react';

const Remortgage = () => {
  return (
    <div>
          <div className="takeaways">
            <h2>Key Takeaways</h2>
            <ul>
              <li>The cost to remortgage takes into account the amount of the mortgage loan, interest rate and term of the mortgage.</li>
              <li>
                The longer the mortgage term, usually means the lower the monthly payments but you’ll pay more in interest overall
              </li>
              <li>
                In repayment mortgages, at the start of the mortgage, you pay more in interest rather than towards the capital
              </li>
              <li>
                There are also additional costs that may incur such as an arrangement fee, legal fee and early repayment charge from your existing lender
              </li>
            </ul>
          </div>
          <div className="transcript">
            <h2>Transcript</h2>
            <p>
              Understanding the cost of your mortgage.<br/><br/>
              Assuming you have a repayment mortgage, three factors determine how much your monthly payment will be the loan amount, the interest rate and the term of the mortgage.<br/><br/>
              Essentially, the longer the term is, the lower your monthly payment will be, but you do end up paying more interest overall.<br/><br/>
              In the same vein, with a shorter term, your payments will be higher, but you pay less interest overall. It's a good idea to work with a mortgage adviser to determine the most appropriate term for you.<br/><br/>
              The average term for first buyers is around 30 years. You can also review and adjust the term of the mortgage when you come to remortgage, which might typically be every 2 - 5 years.<br/><br/>
              For repayment mortgages In the early part of the term, the repayment goes toward just servicing the interest, with a much smaller amount chipping away at paying down the bank's capital.<br/><br/>
              But as you get near the end of your term and the loan decreases, then the part of your monthly payment paying the interest reduces, and you'll start to pay off more of the capital each month.<br/><br/>
            </p>
          </div>
    </div>
  );
};

export default Remortgage;
